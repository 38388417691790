import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import { handleLogin } from "../../components/Auth";
import Logo from "../../images/assets/data.svg";

const GET_TOKEN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        # CustomerAccessToken fields
        accessToken
        expiresAt
      }
      customerUserErrors {
        # CustomerUserError fields
        message
        code
      }
    }
  }
`;

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const handleSubmit = () => {
    const data = {
      email,
      password,
    };
    customerAccessTokenCreate({
      variables: { input: data },
      onCompleted: (result) => {
        if (result.customerAccessTokenCreate.customerUserErrors.length > 0) {
          console.log(
            result.customerAccessTokenCreate.customerUserErrors
          );
          setError(
            result.customerAccessTokenCreate.customerUserErrors[0].message
          );
          setTimeout(() => {
            setError(null);
          }, 5000);
        } else {
          handleLogin(
            result.customerAccessTokenCreate.customerAccessToken.accessToken,
            result.customerAccessTokenCreate.customerAccessToken.expiresAt
          );
          window.location.reload(true);
        }
      },
    });
  };

  const [customerAccessTokenCreate] = useMutation(GET_TOKEN);
  return (
    <Layout>
      <div className="flex mb-5">
        <div className="container px-6  mx-auto justify-center">
          <Logo width={200} height={200} className="my-8 mx-auto d-block" />
          {error ? (
            <div
              className="max-w-sm mx-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          ) : (
            ""
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="max-w-sm mx-auto my-8"
          >
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="email"
                name="floating_email"
                id="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="true"
              />
              <label
                htmlFor="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Email address
              </label>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="password"
                name="floating_password"
                id="floating_password"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="true"
              />
              <label
                htmlFor="floating_password"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Password
              </label>
            </div>

            <button
              type="submit"
              className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center"
            >
              Log in
            </button>
          </form>
          <div className="mt-3 text-md max-w-sm mx-auto">
            <Link to="/register" className="text-gray-500">
              Don't have an account yet?
            </Link>
          </div>
          <div className="mt-1 text-md max-w-sm mx-auto">
            <Link to="/forget" className="text-gray-500">
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
